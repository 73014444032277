import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/strapi/BlockRenderer/BlockRenderer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/components/tailwind-ui/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/views/home/Landing/partials/GetInContactSection/components/ClientView/ContactClientView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/views/home/Landing/partials/WhatWeDoSection/components/ClientView/WhatWeDoClientView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/views/home/Landing/partials/WhatWeOfferSection/components/ClientView/WhatWeOfferClientView.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
