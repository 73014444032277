import { useReducer } from "react";

import { v4 as uuidv4 } from "uuid";

interface ContextType {
  notifications: any[];
  createNotification: (notification: {
    title: string;
    message?: string;
    type: "info" | "success" | "warning" | "error";
    isAutoRemovable?: boolean;
  }) => string;
  removeNotification: (id: string) => void;
}

enum ReducerActionEnum {
  CREATE = "CREATE",
  DELETE = "DELETE",
}

interface ReducerActionType {
  type: ReducerActionEnum;
  payload: any;
}

type ReducerStateType = any[];

export const useNotificationContext = (): ContextType => {
  const [notifications, dispatchNotifications] = useReducer(
    (state: ReducerStateType, action: ReducerActionType) => {
      const { type, payload } = action;

      let result: any[] = [];

      switch (type) {
        case ReducerActionEnum.CREATE:
          result = [...state, payload];
          break;
        case ReducerActionEnum.DELETE:
          result = state.filter(
            (notification) => notification.id !== payload.id,
          );
          break;
      }

      return result;
    },
    [],
  );

  const handleCreateNotification = (params: {
    title: string;
    description?: string;
    type: "info" | "success" | "warning" | "error";
    isAutoRemovable?: boolean;
  }) => {
    const {
      title,
      description,
      type,
      isAutoRemovable = type !== "error",
    } = params;
    const id = uuidv4();

    dispatchNotifications({
      type: ReducerActionEnum.CREATE,
      payload: { id, title, description, type },
    });

    isAutoRemovable &&
      setTimeout(() => {
        dispatchNotifications({
          type: ReducerActionEnum.DELETE,
          payload: { id },
        });
      }, 3000);

    return id;
  };

  const handleDeleteNotification = (id: string) => {
    dispatchNotifications({
      type: ReducerActionEnum.DELETE,
      payload: { id },
    });
  };

  return {
    notifications,
    createNotification: handleCreateNotification,
    removeNotification: handleDeleteNotification,
  };
};
