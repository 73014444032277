"use client";

import React, { useCallback, useContext, useMemo, useState } from "react";
import Notifications from "context/Notifications";
import { TNextClientComponent } from "types/next";
import { useForm } from "hooks/useForm";
import { useNotificationContext } from "hooks/useNotificationContext";
import { useValidation } from "hooks/useValidation";
import InputGroupForm from "components/custom/Form/InputGroup/InputGroupForm";
import SelectMenuForm from "components/custom/Form/SelectMenu/SelectMenuForm";
import TelephoneInputGroup from "components/custom/Form/TelephoneInputGroup";
import NotificationOverlay from "components/custom/Notification/NotificationOverlay";
import { Button } from "components/tailwind-ui/Button";
import Icon from "components/tailwind-ui/Icon";
import { styles } from ".";
type TProps = {
  config?: any;
  reasons?: any;
};
const ContactClientView: TNextClientComponent<TProps> = props => {
  const {
    config,
    reasons
  } = props;
  const {
    validate
  } = useValidation();
  const {
    createNotification
  } = useContext(Notifications);
  const [isSending, setIsSending] = useState(false);
  const handleSendMail = async (parameters: any, callbacks: any) => {
    setIsSending(true);
    const result = await fetch(parameters.url, {
      method: "POST",
      body: JSON.stringify({
        ...parameters.params
      })
    });
    if (result.status !== 200) {
      callbacks.onError();
    } else {
      callbacks.onSuccess();
    }
    setIsSending(false);
  };
  const initialValues = useMemo(() => ({}), []);
  const validationSchema = useMemo(() => ({
    firstName: validate.string({
      isRequired: true
    }),
    lastName: validate.string({
      isRequired: true
    }),
    "telephone-countryCode": validate.object({
      isRequired: true
    }),
    "telephone-number": validate.number({
      isRequired: true
    }),
    emailAddress: validate.email({
      isRequired: true
    }),
    message: validate.string({
      isRequired: true
    }),
    reason: validate.object({
      isRequired: true
    })
  }), [validate]);
  const onSubmit = useCallback((values: any) => {
    handleSendMail({
      url: "/api/send",
      params: {
        ...values,
        to: [config?.attributes?.notificationsEmailAddress]
      }
    }, {
      onError: () => {
        createNotification({
          title: "Something went wrong.",
          description: "Your email could not be delivered.",
          type: "error"
        });
      },
      onSuccess: () => {
        createNotification({
          title: "Success",
          description: "Your email is successfully delivered.",
          type: "success"
        });
      }
    });
  }, [config?.attributes?.notificationsEmailAddress, createNotification]);
  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit
  });
  return <div className="flex flex-col gap-y-8" data-sentry-component="ContactClientView" data-sentry-source-file="ContactClientView.tsx">
      <fieldset className="grid sm:grid-cols-2 gap-8">
        <InputGroupForm form={form} name="firstName" label="First name" placeholder="John" description="Provide a first name."
      // htmlType="text"
      //
      isRequired
      //
      isDisabled={isSending} data-sentry-element="InputGroupForm" data-sentry-source-file="ContactClientView.tsx" />
        <InputGroupForm form={form} name="lastName" label="Last name" placeholder="Doe" description="Provide a last name."
      // htmlType="text"
      //
      isRequired
      //
      isDisabled={isSending} data-sentry-element="InputGroupForm" data-sentry-source-file="ContactClientView.tsx" />
        <InputGroupForm form={form} name="emailAddress" htmlType="email" label="Email address" placeholder="john.doe@invixos.com" description="Provide a valid email address."
      //
      isRequired
      //
      isDisabled={isSending} data-sentry-element="InputGroupForm" data-sentry-source-file="ContactClientView.tsx" />
        <TelephoneInputGroup form={form} name="telephone" label="Telephone number" description="Provide a valid telephone number."
      //
      isRequired
      //
      isDisabled={isSending} data-sentry-element="TelephoneInputGroup" data-sentry-source-file="ContactClientView.tsx" />
      </fieldset>
      <fieldset>
        <SelectMenuForm form={form} name="reason" label="Reason" description="Select a reason."
      //
      // endpoint="/fake/options"
      defaultOptions={reasons.data.reasonOptions.data.map((reason: any) => ({
        ...reason.attributes
      }))}
      //
      isRequired
      //
      isDisabled={isSending} data-sentry-element="SelectMenuForm" data-sentry-source-file="ContactClientView.tsx" />
      </fieldset>
      <fieldset>
        <InputGroupForm form={form} name="message" htmlType="area" label="Message" placeholder="This is my message..." description="Provide a message."
      //
      isRequired
      //
      isDisabled={isSending} data-sentry-element="InputGroupForm" data-sentry-source-file="ContactClientView.tsx" />
      </fieldset>
      <Button disabled={isSending}
    // type="submit"
    onClick={() => {
      // Enable all fields to be validated.
      form.setFieldTouched("firstName");
      form.setFieldTouched("lastName");
      form.setFieldTouched("telephone-countryCode");
      form.setFieldTouched("telephone-number");
      form.setFieldTouched("emailAddress");
      form.setFieldTouched("message");
      form.setFieldTouched("reason");

      // Validate fields and submit.
      if (Object.keys(form.errors).length === 0) {
        form.handleSubmit();
      }
    }} data-sentry-element="Button" data-sentry-source-file="ContactClientView.tsx">
        {isSending ? <div className="flex items-center gap-x-2">
            <Icon type="solid" accessor="spinner" className="w-4 animate-spin !text-white" />
            Sending...
          </div> : "Send"}
      </Button>
    </div>;
};
const NotificationsView = () => {
  const {
    notifications
  } = useContext(Notifications);
  return <NotificationOverlay.Container data-sentry-element="unknown" data-sentry-component="NotificationsView" data-sentry-source-file="ContactClientView.tsx">
      {notifications.map(notification => {
      const {
        id,
        title,
        description,
        type
      } = notification;
      let icon = <Icon accessor="check" className="px-2" />;
      switch (type) {
        case "error":
          icon = <Icon accessor="circle-xmark" className="px-2 text-xl text-error-600" />;
          break;
        case "warning":
          icon = <Icon accessor="circle-exclamation" className="px-2 text-xl text-warning-600" />;
          break;
        case "info":
          icon = <Icon accessor="circle-info" className="px-2 text-xl text-info-600" />;
          break;
        case "success":
          icon = <Icon accessor="circle-check" className="px-2 text-xl text-success-600" />;
          break;
        default:
          icon = <Icon accessor="check" className="px-2" />;
          break;
      }
      return <NotificationOverlay.Item key={id} styles={{
        item: {
          panel: {
            container: "!rounded-sm bg-gray-700"
          }
        }
      }}>
            {({
          onClose
        }) => <div className={styles.container}>
                <div className={styles.body}>
                  {icon}
                  <div className="ml-6">
                    <h2 className={styles.heading}>{title}</h2>
                    <p className={styles.description}>{description}</p>
                  </div>
                </div>
                <div className={styles.foot}>
                  <Button outline onClick={onClose} className={styles.button.container}>
                    <Icon className={styles.icon} accessor="times" />
                  </Button>
                </div>
              </div>}
          </NotificationOverlay.Item>;
    })}
    </NotificationOverlay.Container>;
};
const ContextContainer: any = (props: any) => {
  const notificationContext = useNotificationContext();
  return <Notifications.Provider value={notificationContext} data-sentry-element="unknown" data-sentry-component="ContextContainer" data-sentry-source-file="ContactClientView.tsx">
      <ContactClientView {...props} data-sentry-element="ContactClientView" data-sentry-source-file="ContactClientView.tsx" />
      <NotificationsView data-sentry-element="NotificationsView" data-sentry-source-file="ContactClientView.tsx" />
    </Notifications.Provider>;
};
export default ContextContainer;